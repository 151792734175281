import { utilityServices } from "./utilityServices";
import { endOfDecade } from "date-fns";
function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

function getUserName() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const userName = user && user.userName;
  return userName;
}

function getOrgName() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const organizationName = user && user.organizationName;
  return organizationName;
}

async function createOrganization(organization) {
  const url = process.env.REACT_APP_PROVISIONING_CREATE_ORGANIZATION;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(organization)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}
async function editOrganization(organization) {
  const url = process.env.REACT_APP_PROVISIONING_CREATE_ORGANIZATION;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(organization)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function editSite(siteId, site) {
  const url = `${process.env.REACT_APP_PROVISIONING_CREATE_SITE}/${siteId}`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(site)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw await response.json();
    }
  } catch (error) {
    let err = {
      status: error.message,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function deleteOrgById(orgId) {
  const url = `${process.env.REACT_APP_PROVISIONING_DELETE_ORGANIZATION_BY_ID}/${orgId}`;
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    return response;
  } catch (error) {
    console.error("delete has failed :", error.message);
  }
}

async function getOrganizations(pageSize = "", pageIndex = "") {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_ALL_ORGANIZATIONS}?page=${pageIndex}&size=${pageSize}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getRoles(organization, pageSize, pageIndex) {
  const url = `/authgateway/provisioningService/roles?orgName=${organization}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getRolesByOrgId(orgId) {
  const url = `${process.env.REACT_APP_GET_ROLES_BY_ORG_ID}?orgId=${orgId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getOrgByName(name) {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_ORGANIZATION_BY_NAME}?orgName=${name}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}
async function getOrgDetailsbyID(id) {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_ORGANIZATION_BY_NAME}/${id}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      Authorization: `Bearer ${getToken()}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}
async function getOrgHeirarchy(orgId) {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_ORGANIZATION_HEIRARCHY}/${orgId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function createSite(site) {
  const url = process.env.REACT_APP_PROVISIONING_CREATE_SITE;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(site)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    console.error("error in api call", error);
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getUsers(page, size) {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_USERS}?page=${page}&size=${size}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      Authorization: `Bearer ${getToken()}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function createUser(user) {
  const url = process.env.REACT_APP_PROVISIONING_CREATE_USER;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(user)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    console.error("error in user create", error);
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function editUser(values, userId) {
  const url = `${process.env.REACT_APP_PROVISIONING_CREATE_USER}/${userId}`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function validateDevice(type, serialNumber, siteUUID) {
  let url = `${process.env.REACT_APP_PROVISIONING_VALIDATE_DEVICE}/${serialNumber}`;
  if (siteUUID) {
    url = url + `/?siteUUID=${siteUUID}`;
  }
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      Authorization: `Bearer ${getToken()}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw await response.json();
    }
  } catch (error) {
    console.error("validating device failed :", error);
    throw error;
  }
}

async function getSites(pageSize = "", pageIndex = "") {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_SITES}?page=${pageIndex}&size=${pageSize}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getSitesByOrgName(orgName) {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_SITES_BY_ORG_NAME}?orgName=${orgName}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}
async function getSitesBySubOrgs(subOrgs, pageIndex = "", pageSize = "") {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_SITES_BY_SUB_ORGS}id?subOrganizationName=${subOrgs}&page=${pageIndex}&size=${pageSize}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getAllSitesByOrg(organizationName) {
  const url = `/authgateway/provisioningService/sites/all/id?orgName=${organizationName}&page=0&size=1000`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getSiteById(siteId) {
  const url = `/authgateway/provisioningService/sites/${siteId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getRatingTreeDate(organizationName){
  const url = `/authgateway/analyticService/orgRating/dates?organization=${organizationName}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
};

async function getSiteByUUID(uuid) {
  const url = `/authgateway/provisioningService/sites?uuid=${uuid}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getSiteByName(siteName) {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_SITE}/siteName?name=${siteName}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getPrivileges() {
  const url = process.env.REACT_APP_PROVISIONING_GET_PRIVILEGES;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      Authorization: `Bearer ${getToken()}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function createRole(role) {
  const url = process.env.REACT_APP_PROVISIONING_CREATE_ROLE;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(role)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

const getSubOrgHeirarchy = async suborg => {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_ORGANIZATION_SUBHEIRARCHY}?orgName=${suborg}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
};

const getSubOrganizationsMetaList = async orgName => {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_SUB_ORGANIZATIONS_META_LIST}${orgName}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
};

const getSubOrganizationsByOrgName = async (
  orgName,
  pageIndex = "",
  pageSize = ""
) => {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_SUB_ORGANIZATIONS_BY_ORG_NAME}?orgName=${orgName}&page=${pageIndex}&size=${pageSize}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error:
        error.status === 401 ? true : !!error.body ? await error.json() : true
    };
    throw err;
  }
};

const getSubOrganizationsByOrgNameWithoutNestedObject = async orgName => {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_SUB_ORGANIZATIONS_BY_ORG_NAME_1}?orgName=${orgName}&${process.env.REACT_APP_FETCH_PAGE_SIZE}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error:
        error.status === 401 ? true : !!error.body ? await error.json() : true
    };
    throw err;
  }
};

const updateSitesBookmarks = async sites => {
  const userName = getUserName();
  const url = `${process.env.REACT_APP_PROVISIONING_UPDATE_SITE_BOOKMARKS}${userName}`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(sites)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
};

const getPrivilegesByRoleName = async role => {
  const url = `/authgateway/provisioningService/roles/${role}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
};

const getAllUsers = async () => {
  const url = `/authgateway/provisioningService/users/allUserSitesAssociation?pageable=false`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
};

const getUserAssociatedSites = async (pageSize = "", pageIndex = "") => {
  const userName = getUserName();
  const url = `/authgateway/provisioningService/users/allSitesAssociation?userName=${userName}&page=${pageIndex}&size=${pageSize}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
};
const getChillingUnitData = async siteId => {
  const url = `${process.env.REACT_APP_PROVISIONING_CHILLING_UNIT_DATA}${siteId}/chillingUnits/all`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
};

const getOrgMetaList = async org => {
  const url = `/authgateway/provisioningService/organizations/orgMetaList?orgName=${org}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      Authorization: `Bearer ${getToken()}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
};

const getUserByUserName = async user => {
  const url = `/authgateway/provisioningService/users/userDetails?userName=${user}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      Authorization: `Bearer ${getToken()}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json();

    // console.log("user data ", data);
    return data;
  } catch (error) {
    console.error("getting user details failed :", error.message);
    throw error;
  }
};

const changePassword = async (oldPassword, newPassword) => {
  const url = "/authgateway/provisioningService/users/updatePassword";

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify({ oldPassword: oldPassword, newPassword: newPassword })
  };
  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      return {
        message: "Successfully Updated. Please Login Again!!",
        status: response.ok
      };
    } else {
      return {
        message: await response.json().then(object => object.message),
        status: false
      };
    }
  } catch (error) {
    console.error("failed updating password :", error.message);
    throw error;
  }
};

const resetUserPassword = async (userName, newPassword) => {
  const url = `/authgateway/provisioningService/users/resetPassword?userName=${userName}`;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify({ newPassword: newPassword })
  };
  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      return {
        message: "Successfully Updated.",
        status: response.ok
      };
    } else {
      const message = await response.json();
      // console.log(message);
      // return {
      //   message: await response.json().then(object => object.message),
      //   status: false
      // };
    }
  } catch (error) {
    console.error("failed updating password :", error.message);
    throw error;
  }
};

const getReportsData = async language => {
  // URL for fetching reports
  //const url = `${process.env.REACT_APP_GET_REPORTS}?locale=${language}`;
  const url = `${process.env.REACT_APP_GET_REPORTS}`;
  // const url = [
  //   {
  //     "name": "Enquire Loan details",
  //     "displayName": "Enquire Loan details",
  //     "active": true,
  //     "type": "JASPER",
  //     "category": "Fintech",
  //     "url": "https://cv2.smartmoo.com/jasperserver/flow.html?_flowId=viewReportFlow&_flowId=viewReportFlow&ParentFolderUri=%2FReports%2FFARMER_APP&reportUnit=%2FReports%2FFARMER_APP%2Fenquire_loans&standAlone=true&locale=en&reportParams=edtWW7fqNP5ekRWVK2MiSNfHRrWPrebZ7yt1/GCC9xL0/mV0ue64g2HnLKcJVHEOsqCKNQy1ucId8f5YsCB+hmczd+72nhMYn8fbXFy0EKH0vOyF864lMWlwa94dgspKIHu0UcCOk3HF9wmXaCj7Jw==&userName=stp.portal.admin&organization=Stellapps&role=ROLE_ADMIN"
  //   },
  //   {
  //     "name": "smartAMCU 1",
  //     "displayName": "smartAMCU 1",
  //     "active": true,
  //     "type": "JASPER",
  //     "category": "smartAMCU",
  //     "url": "https://cv2.smartmoo.com/jasperserver/flow.html?_flowId=viewReportFlow&_flowId=viewReportFlow&ParentFolderUri=%2FReports%2FFARMER_APP&reportUnit=%2FReports%2FFARMER_APP%2Fenquire_loans&standAlone=true&locale=en&reportParams=edtWW7fqNP5ekRWVK2MiSNfHRrWPrebZ7yt1/GCC9xL0/mV0ue64g2HnLKcJVHEOsqCKNQy1ucId8f5YsCB+hmczd+72nhMYn8fbXFy0EKH0vOyF864lMWlwa94dgspKIHu0UcCOk3HF9wmXaCj7Jw==&userName=stp.portal.admin&organization=Stellapps&role=ROLE_ADMIN"
  //   },
  //   {
  //     "name": "smartAMCU 2",
  //     "displayName": "smartAMCU 2",
  //     "active": true,
  //     "type": "JASPER",
  //     "category": "smartAMCU",
  //     "url": "https://cv2.smartmoo.com/jasperserver/flow.html?_flowId=viewReportFlow&_flowId=viewReportFlow&ParentFolderUri=%2FReports%2FFARMER_APP&reportUnit=%2FReports%2FFARMER_APP%2Fenquire_loans&standAlone=true&locale=en&reportParams=edtWW7fqNP5ekRWVK2MiSNfHRrWPrebZ7yt1/GCC9xL0/mV0ue64g2HnLKcJVHEOsqCKNQy1ucId8f5YsCB+hmczd+72nhMYn8fbXFy0EKH0vOyF864lMWlwa94dgspKIHu0UcCOk3HF9wmXaCj7Jw==&userName=stp.portal.admin&organization=Stellapps&role=ROLE_ADMIN"
  //   }
  // ]
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getToken()}`,
      // authToken: localStorage.getItem("idToken")
      Authorization: `Bearer ${localStorage.getItem("idToken")}`
      //authToken: localStorage.getItem("idToken")
    }
  };

  //setting data from API

  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json().then(a => a);
    const result = await utilityServices.jsonConverterForReports(
      data,
      // [
      //   {
      //     "name": "Enquire Loan details",
      //     "displayName": "Enquire Loan details",
      //     "active": true,
      //     "type": "JASPER",
      //     "category": "Fintech",
      //     "url": "https://cv2.smartmoo.com/jasperserver/flow.html?_flowId=viewReportFlow&_flowId=viewReportFlow&ParentFolderUri=%2FReports%2FFARMER_APP&reportUnit=%2FReports%2FFARMER_APP%2Fenquire_loans&standAlone=true&locale=en&reportParams=edtWW7fqNP5ekRWVK2MiSNfHRrWPrebZ7yt1/GCC9xL0/mV0ue64g2HnLKcJVHEOsqCKNQy1ucId8f5YsCB+hmczd+72nhMYn8fbXFy0EKH0vOyF864lMWlwa94dgspKIHu0UcCOk3HF9wmXaCj7Jw==&userName=stp.portal.admin&organization=Stellapps&role=ROLE_ADMIN"
      //   },
      // ],
      "category",
      "name"
    );
    //console.log("From Prov Service:- ", { REPORTS: result });
    return { REPORTS: result };
  } catch (error) {
    console.error("getting Reports details failed :", error.message);
    throw error;
  }
};

const setReportHeaders = async () => {
  try {
    const data = await getReportsData("en");
    // console.log(data);
    const headers = data.REPORTS.map(res => res.key);
    return headers;
  } catch (error) {
    console.error("getting Reports Headers details failed :", error.message);
    throw error;
  }
};

const getTicketIssues = async () => {
  const url = `/authgateway/provisioningService/metadata/user/issueTypes`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };

  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("getting Ticket Issues failed :", error.message);
    throw error;
  }
};

const postTicketIssues = async ({ subject, description, fileUpload }) => {
  const url = "/issues/issues.json";
  const id = 36;
  let upload = [];
  const priority_id = 1;
  const priority = {
    id: 2
  };
  const status = {
    id: 1
  };
  const name = [
    {
      value: await getUserName(),
      id: 23
    }
  ];
  const assigned = 128;
  const tracker = 3;
  if (fileUpload.length) {
    upload = await Promise.all(
      fileUpload.map(element => postFile(element))
    ).then(result => result);
  }
  const issue = {
    project_id: id,
    subject: subject,
    priority_id: priority_id,
    description: description,
    status: status,
    priority: priority,
    assigned_to_id: assigned,
    tracker_id: tracker,
    uploads: upload,
    custom_fields: name
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Redmine-API-Key": process.env.REACT_APP_REDMINE_API_KEY,
      Authorization: "Bearer " + process.env.REACT_APP_REDMINE_API_KEY
    },
    body: JSON.stringify({ issue })
  };

  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (response.ok) {
      return { data: "Ticket ID: " + data.issue.id, status: response.ok };
    } else {
      return { data: data.error, status: response.ok };
    }
  } catch (error) {
    console.error("Creating REDMINE Issues failed :", error.message);
    throw error.message;
  }
};

const postFile = async file => {
  const fileName = file.name;
  const url = `/issues/uploads.json?filename=${fileName}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/octet-stream",
      "X-Redmine-API-Key": process.env.REACT_APP_REDMINE_API_KEY,
      Authorization: "Bearer " + process.env.REACT_APP_REDMINE_API_KEY
    },
    body: file
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      const data = await response.json();
      return {
        token: data.upload.token,
        filename: fileName,
        content_type: file.type
      };
    } else {
      return "Unable to create file. Please try later.";
    }
  } catch (error) {
    console.error("File Error :", error.message);
    throw error;
  }
};

async function getCheckListTemplates(organization, page, size) {
  const url = `${process.env.REACT_APP_PROVISIONING_GET_CHECKLIST_TEMPLATES}${organization}&page=${page}&size=${size}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Fetch Bearer Token for Loan API's - Generate OTP
async function generateOtp(mobileNum) {
  //const url = `/authgateway/provisioningService/template`;
  //const url = 'http://164.52.196.23/farmercoreservice/igo/login/generate/otp?mobileNum=9686034331';
  const url = `${process.env.REACT_APP_GENERATE_OTP}?mobileNum=${mobileNum}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfC'
    },
    //console.log("Post Method is Success", response);
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
      //console.log("Bearer Token generate response:-", response.json());
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Fetch Bearer Token for Loan API's - Verify OTP
async function verifyOtp(mobileNum,otp,transactionId) {
  //const url = `/authgateway/provisioningService/template`;
  //const url = 'http://164.52.196.23/farmercoreservice/igo/login/generate/otp?mobileNum=9686034331';
  const url = `${process.env.REACT_APP_VERIFY_OTP}?mobileNum=${mobileNum}&otp=${otp}&transactionId=${transactionId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfC'
    },
    //console.log("Post Method is Success", response);
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      console.log(response.headers.get('Authorization'));
      localStorage.setItem("bearerToken", response.headers.get('Authorization'));
      //console.log("Bearer Token generate response:-", data);
      return await response.json();
      //console.log("Bearer Token generate response:-", response.json());
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}



// Fetch Bearer Token for Loan API's - Verify OTP New
//async function verifyOtpNew(mobileNum,otp) {
async function verifyOtpNew(mobileNum) {
  //const url = `/authgateway/provisioningService/template`;
  //const url = 'http://164.52.196.23/farmercoreservice/igo/login/generate/otp?mobileNum=9686034331';
  //const url = `${process.env.REACT_APP_VERIFY_OTP_NEW}?mobileNum=${mobileNum}&otp=${otp}`;
  const url = `${process.env.REACT_APP_VERIFY_OTP_NEW}?mobileNum=${mobileNum}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfC'
    },
    //console.log("Post Method is Success", response);
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      //console.log(response.headers.get('Authorization'));
      localStorage.setItem("bearerToken", response.headers.get('Authorization'));
      //console.log("Bearer Token generate response:-", data);
      return await response.json();
      //console.log("Bearer Token generate response:-", response.json());
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getAllOrgList() {
    const url = `financeservice/nexgen/farmer/orgList`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQff',
        Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
        authToken: localStorage.getItem("idToken")
      }
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        return await response.json();
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
  }

//Get Type and Category Values In LoanInsurance Module
async function getTypeAndCategoryInLoan() {
  const url = `financeservice/nexgen/generic/metadata`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQff',
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

//Loan Insurance API - Fetch by orgId 
async function getLoanInsurance(organizationId) {
//async function getLoanInsurance(organization, page, size) {
  //const url = `${process.env.REACT_APP_PROVISIONING_GET_CHECKLIST_TEMPLATES}${organization}&page=${page}&size=${size}`;
  const url = `${process.env.REACT_APP_PROVISIONING_GET_LOAN_INSURANCE}/${organizationId}`;
  //console.log("loan url:- ", url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": process.env.REACT_APP_PROVISIONING_GET_LOAN_INSURANCE_API_KEY,
      //Authorization: `Bearer ${getToken()}`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMzExMDI0NCIsImlhdCI6MTY2MTQ5MzQ5OCwiZXhwIjoxNjYyNzAzMDk4fQ.MJBr7UcmH6MrzHba0UKKB-a6NHr1kq5adTJcnF_mnkNCD6cVVR0X_4Oxxz7qLTp5m3oK1ijdkyPV1Dyr7WX1DQ`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI0MTU0OTI5MiIsImlhdCI6MTY2Mjk2NTUwNCwiZXhwIjoxNjY0MTc1MTA0fQ.1hSrRFFytQpg8lrzuKU_PBIx31FIW0YiMvNWdOq8X4cRuY0rUVxQ8ucBIr79--dq4AnaHYUT8PSqESz6OtPlyA`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI3OTcwMjA2MiIsImlhdCI6MTY2NDM2NDI0OSwiZXhwIjoxNjY1NTczODQ5fQ.W13YvHOSCaUtHhp4WS1XBirLCQvMDfaIOoXa3QSAYNpFOFwZAdr5AjCKAISZ6ANAEeIBe5iPBbs6uvJ7ew8e-Q`,
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI3OTcwMjA2MiIsImlhdCI6MTY2NTY0NjE0MywiZXhwIjoxNjY2ODU1NzQzfQ.6WDkgRNAxeDKh46avhGSmsQW8uJWc12neFzHITEy6IA5_2mM8cL7X7j6dF-Guov-g1XZ8wrmrYGNMaT9NBKO3A`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzMDAyOTk1MiIsImlhdCI6MTY2NjY4MTIzNCwiZXhwIjoxNjY3ODkwODM0fQ.9nkYHfXHCA_jwuHlpo3e_JxYMdIY2vLddUYeMO9XLbeZHuWuE7VfTfKi4Pto6xKOWkRhCIg7ypLjL9G9ZDgxxw`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Loan Insurance API - Post
async function createLoanInsurance(loanInsurance) {
  //const url = `/authgateway/provisioningService/template`;
  const url = `${process.env.REACT_APP_PROVISIONING_ADD_LOAN_INSURANCE}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": process.env.REACT_APP_PROVISIONING_GET_LOAN_INSURANCE_API_KEY,
      //Authorization: `Bearer ${getToken()}`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI0MTU0OTI5MiIsImlhdCI6MTY2Mjk2NTUwNCwiZXhwIjoxNjY0MTc1MTA0fQ.1hSrRFFytQpg8lrzuKU_PBIx31FIW0YiMvNWdOq8X4cRuY0rUVxQ8ucBIr79--dq4AnaHYUT8PSqESz6OtPlyA`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI3OTcwMjA2MiIsImlhdCI6MTY2NDM2NDI0OSwiZXhwIjoxNjY1NTczODQ5fQ.W13YvHOSCaUtHhp4WS1XBirLCQvMDfaIOoXa3QSAYNpFOFwZAdr5AjCKAISZ6ANAEeIBe5iPBbs6uvJ7ew8e-Q`,
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI3OTcwMjA2MiIsImlhdCI6MTY2NTY0NjE0MywiZXhwIjoxNjY2ODU1NzQzfQ.6WDkgRNAxeDKh46avhGSmsQW8uJWc12neFzHITEy6IA5_2mM8cL7X7j6dF-Guov-g1XZ8wrmrYGNMaT9NBKO3A`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzMDAyOTk1MiIsImlhdCI6MTY2NjY4MTIzNCwiZXhwIjoxNjY3ODkwODM0fQ.9nkYHfXHCA_jwuHlpo3e_JxYMdIY2vLddUYeMO9XLbeZHuWuE7VfTfKi4Pto6xKOWkRhCIg7ypLjL9G9ZDgxxw`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(loanInsurance)
    //console.log("Post Method is Success", response);
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
      //console.log("Post Method is Success", response);
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Loan Insurance API - By ProductId
async function getLoanInsuranceByProductId(loanInsuranceProductId) {
  const url = `/financeservice/nexgen/loanInsurance/offer/${loanInsuranceProductId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": process.env.REACT_APP_PROVISIONING_GET_LOAN_INSURANCE_API_KEY,
      //Authorization: `Bearer ${getToken()}`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMzExMDI0NCIsImlhdCI6MTY2MTQ5MzQ5OCwiZXhwIjoxNjYyNzAzMDk4fQ.MJBr7UcmH6MrzHba0UKKB-a6NHr1kq5adTJcnF_mnkNCD6cVVR0X_4Oxxz7qLTp5m3oK1ijdkyPV1Dyr7WX1DQ`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI0MTU0OTI5MiIsImlhdCI6MTY2Mjk2NTUwNCwiZXhwIjoxNjY0MTc1MTA0fQ.1hSrRFFytQpg8lrzuKU_PBIx31FIW0YiMvNWdOq8X4cRuY0rUVxQ8ucBIr79--dq4AnaHYUT8PSqESz6OtPlyA`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI3OTcwMjA2MiIsImlhdCI6MTY2NDM2NDI0OSwiZXhwIjoxNjY1NTczODQ5fQ.W13YvHOSCaUtHhp4WS1XBirLCQvMDfaIOoXa3QSAYNpFOFwZAdr5AjCKAISZ6ANAEeIBe5iPBbs6uvJ7ew8e-Q`,
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI3OTcwMjA2MiIsImlhdCI6MTY2NTY0NjE0MywiZXhwIjoxNjY2ODU1NzQzfQ.6WDkgRNAxeDKh46avhGSmsQW8uJWc12neFzHITEy6IA5_2mM8cL7X7j6dF-Guov-g1XZ8wrmrYGNMaT9NBKO3A`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzMDAyOTk1MiIsImlhdCI6MTY2NjY4MTIzNCwiZXhwIjoxNjY3ODkwODM0fQ.9nkYHfXHCA_jwuHlpo3e_JxYMdIY2vLddUYeMO9XLbeZHuWuE7VfTfKi4Pto6xKOWkRhCIg7ypLjL9G9ZDgxxw`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Edit loan Insurance
async function editLoanInsurance(productId, loanInsurance) {
//async function editLoanInsurance(checklistTemplateId, checklistTemplate) {
  //const url = `/authgateway/provisioningService/template?templateId=${checklistTemplateId}`;
  const url = `/financeservice/nexgen/loanInsurance/offer`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": process.env.REACT_APP_PROVISIONING_GET_LOAN_INSURANCE_API_KEY,
      //Authorization: `Bearer ${getToken()}`,
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI3OTcwMjA2MiIsImlhdCI6MTY2NTY0NjE0MywiZXhwIjoxNjY2ODU1NzQzfQ.6WDkgRNAxeDKh46avhGSmsQW8uJWc12neFzHITEy6IA5_2mM8cL7X7j6dF-Guov-g1XZ8wrmrYGNMaT9NBKO3A`,
      //Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzMDAyOTk1MiIsImlhdCI6MTY2NjY4MTIzNCwiZXhwIjoxNjY3ODkwODM0fQ.9nkYHfXHCA_jwuHlpo3e_JxYMdIY2vLddUYeMO9XLbeZHuWuE7VfTfKi4Pto6xKOWkRhCIg7ypLjL9G9ZDgxxw`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(loanInsurance)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Fetch Bearer Token for Retailer API's
  async function fetchRetailerToken(mobileNum) {
    const url = `${process.env.REACT_APP_RETAILER_TOKEN}?mobileNum=${mobileNum}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfR'
      },
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        console.log(response.headers.get('Authorization'));
        localStorage.setItem("bearerTokenRetailer", response.headers.get('Authorization'));
        //console.log("Bearer Token generate response:-", data);
        return await response.json();
        //console.log("Bearer Token generate response:-", response.json());
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
  }

// Get Retailer List 
async function getRetailerList() {
  const url = `userSignupService/nexgen/retailer/list`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfR',
      Authorization: `Bearer ${localStorage.getItem("bearerTokenRetailer")}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Retailer - By id
async function getRetailerById(retailerId) {
  const url = `/userSignupService/nexgen/retailer/details/${retailerId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfR',
      Authorization: `Bearer ${localStorage.getItem("bearerTokenRetailer")}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Edit Retailer
async function editRetailer(productId, retailer) {
    const url = `/userSignupService/nexgen/admin/retailer/details`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfR',
        Authorization: `Bearer ${localStorage.getItem("bearerTokenRetailer")}`,
        authToken: localStorage.getItem("idToken")
      },
      body: JSON.stringify(retailer)
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        return await response.json();
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
  }

//Get Status Dropdown Values in Retailer Edit flow
async function getStatusValuesInRetailerEdit() {
  const url = `userSignupService/nexgen/retailer/status/list`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfR',
      Authorization: `Bearer ${localStorage.getItem("bearerTokenRetailer")}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Retailer Import Failed Records
async function getRetailerImportFailedRecords(failedRecordRequestId) {
  const url = `userSignupService/nexgen/retailer/failedrecords?requestId=${failedRecordRequestId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfR',
      Authorization: `Bearer ${localStorage.getItem("bearerTokenRetailer")}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Fetch Bearer Token for COD API's
async function fetchCODToken(mobileNum) {
  const url = `${process.env.REACT_APP_COD_TOKEN}?mobileNum=${mobileNum}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQeU'
    },
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      localStorage.setItem("bearerTokenCOD", response.headers.get('Authorization'));
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Fetch COD List Based on Status
async function fetchCODListBasedOnStatus() {
  const url = `orderPaymentService/get/all/records`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Api-Key": 'ee25280aac4145afafa960a9975c00eb',
      "status": localStorage.getItem("CODStatus"),
      Authorization: `Bearer ${localStorage.getItem("bearerTokenCOD")}`,
      authToken: localStorage.getItem("idToken")
    },
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      //localStorage.setItem("bearerTokenCOD", response.headers.get('Authorization'));
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Agent Payment List 
async function getAgentPaymentList(fromDate,toDate) {
  const url = `datasyncservice/nexgen/agent/payment-details/all?fromDate=${fromDate}&toDate=${toDate}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfC',
      //Authorization: `Bearer ${getToken()}`,
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      //console.log("try if part", response);
      return await response.json();
    } else {
      //console.log("try Else part", response);
      throw response;
    }
  } catch (error) {
    //console.log("catch part", error);
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Agent List 
async function getAgentList() {
  const url = `stellappsAgentService/api/agent`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk',
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get States
async function getStatesAgent() {
  const url = `stellappsAgentService/api/geography/state`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk',
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Orgs for Agent Module
async function getOrgsAgent() {
  const url = `stellappsAgentService/api/organization`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk',
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Districts
async function getDistrictsAgent(state) {
  const url = `stellappsAgentService/api/geography/district?state=${state}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk',
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Taluks
async function getTaluksAgent(district) {
  const url = `stellappsAgentService/api/geography/taluk?district=${district}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk',
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Villages
async function getVillagesAgent(taluk) {
  const url = `stellappsAgentService/api/geography/village?taluk=${taluk}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk',
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Validate Agent
async function validateAgent(agent) {
  const url = `/stellappsAgentService/api/agent/available`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk',
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(agent)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Create Agent
async function createAgent(agent) {
  const url = `/stellappsAgentService/api/agent`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk',
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(agent)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Agent - By Id
async function getAgentById(agentId) {
  const url = `/stellappsAgentService/api/agent/${agentId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk',
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Edit Agent
async function editAgent(id, agent) {
  const url = `/stellappsAgentService/api/agent`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk',
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(agent)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getBmcRating(bmcId, startDate, endDate) {
  // ${bmcId} with BMC_1112 when integrated
  // const url = `ratingService/bmcStarRating/ratingDetails/BMC_1592/2020-05-01/2020-05-31`;
  const url = `authgateway/analyticService/rating/ratingDetails/${bmcId}/${startDate}/${endDate}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}
async function getOverAllBmcRating(bmc, startDate, endDate) {
  // const url = `/authgateway/ratingService/bmcStarRating/overallRating/2020-05-01/2020-05-31`;
  const url = `/authgateway/analyticService/rating/overallRating/${startDate}/${endDate}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(bmc)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function createChecklistTemplate(checklistTemplate) {
  const url = `/authgateway/provisioningService/template`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(checklistTemplate)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getChecklistName(orgName) {
  const url = `/authgateway/provisioningService/template?orgName=${orgName}&page=0&size=100`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function editChecklistTemplate(checklistTemplateId, checklistTemplate) {
  const url = `/authgateway/provisioningService/template?templateId=${checklistTemplateId}`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": process.env.REACT_APP_PROVISIONING_API_KEY,
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(checklistTemplate)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getCheckListTemplateById(checklistTemplateId) {
  const url = `/authgateway/provisioningService/template/${checklistTemplateId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": process.env.REACT_APP_PROVISIONING_API_KEY,
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getOrgSiteStructure() {
  const url = `/authgateway/provisioningService/sites/orgSiteStructure`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getOrgRatingStructure(orgName, startDate, endDate){
  const url = `/authgateway/analyticService/orgRating/tree?organization=${orgName}&startTime=${startDate}&endTime=${endDate}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function methodOfCooling() {
  const url = `${process.env.REACT_APP_GET_METHOD_OF_COOLING}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

const getLocaleList = async org => {
  const url = `${process.env.REACT_APP_METADATA_LOCALE_TYPES}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
};

const getTimeZoneList = async org => {
  const url = `${process.env.REACT_APP_METADATA_TIMEZONE_TYPES}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
};

async function getGeographyByPincode(pincode){
  const url = `${process.env.REACT_APP_GET_GEOGRAPHY_INFO_BY_PINCODE}/${pincode}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getAllStates(){
  const url = `${process.env.REACT_APP_GET_ALL_STATES}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getDistricts(selectedState){
  const url = `${process.env.REACT_APP_GET_DISTRICT}?state=${selectedState}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getTaluk(selectedDistrict){
  const url = `${process.env.REACT_APP_GET_TALUK}?district=${selectedDistrict}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getVillages(selectedTaluk){
  const url = `${process.env.REACT_APP_GET_VILLAGE}?taluk=${selectedTaluk}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getAllOrgs(){
  const url = process.env.REACT_APP_GET_All_ORGANIZATION;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getCCByOrg(value){
  //`${process.env.REACT_APP_GET_VILLAGE}?taluk=${selectedTaluk}`;
  const url =  `${process.env.REACT_APP_GET_CC_BY_ORG}?orgId=${value}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getVlccByOrg(value){
  //`${process.env.REACT_APP_GET_VILLAGE}?taluk=${selectedTaluk}`;
  const url =  `${process.env.REACT_APP_GET_VLCC_BY_CC}?ccId=${value}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function uploadPaymentTemplate(formData) {
  const url = process.env.REACT_APP_PAYMENT_TEMPLATE_UPLOAD;

  if (!url) {
    console.error(" Error: Upload URL is not defined in .env file");
    throw new Error("Upload URL is missing. Please check your environment variables.");
  }

  const authToken = localStorage.getItem("idToken");
  const bearerToken = localStorage.getItem("bearerToken");

  if (!authToken || !bearerToken) {
    console.error("Error: Missing authentication tokens.");
    throw new Error("Authentication failed. Please log in again.");
  }

  if (!(formData instanceof FormData)) {
    console.error("Invalid FormData:", formData);
    throw new Error("Invalid file upload data.");
  }

  const requestOptions = {
    method: "POST",
    headers: {
      "authToken": authToken,
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      "Authorization": `Bearer ${bearerToken}`,
    },
    body: formData,
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      let errorMessage = `HTTP ${response.status}: `;
      try {
        const errorResponse = await response.json();
        errorMessage += errorResponse.message || "Unknown error";
      } catch {
        errorMessage += "Unknown error (Non-JSON response)";
      }
      console.error(" Upload failed:", errorMessage);
      throw new Error(errorMessage);
    }

    return response

  } catch (error) {
    console.error("Network or API Error:", error.message);
    throw new Error("Network error: Unable to connect to the server. Please try again.");
  }
}



async function updatePaymentDetails(value) {
  const url = process.env.REACT_APP_UPDATE_PAYMENT_DETAILS;

  const idToken = localStorage.getItem("idToken");
  const bearerToken = localStorage.getItem("bearerToken");

  const requestOptions = {
    method: "PUT", 
    headers: {
      "Content-Type": "application/json",

      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      ...(idToken && { authToken: idToken }), // Add only if present
      ...(bearerToken && { Authorization: `Bearer ${bearerToken}` }) // Add only if present
    },
    body: JSON.stringify(value) // Convert payload to JSON string
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      // Try parsing error response safely
      let errorData;
      try {
        errorData = await response.json();
      } catch {
        errorData = { message: "Unknown error occurred" };
      }

      throw { status: response.status, error: errorData };
    }

    return await response.json(); // Return response data
  } catch (error) {
    console.error("Error updating payment details:", error);
    throw error;
  }
}

async function deletePaymentDetails(id) {
  const url = `${process.env.REACT_APP_DELETE_PAYMENT_DETAILS_BY_ID}/${id}`;

  const idToken = localStorage.getItem("idToken");
  const bearerToken = localStorage.getItem("bearerToken");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",

      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      ...(idToken && { authToken: idToken }), // Add only if present
      ...(bearerToken && { Authorization: `Bearer ${bearerToken}` }) // Add only if present
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    return response;
  } catch (error) {
    console.error("delete has failed :", error.message);
  }
}
async function getAllPassbookDetails({ fromDate, toDate, size = 100, page = 0 }) {
  const baseUrl = process.env.REACT_APP_GET_ALL_PASSBOOK_DETAILS;
  const url = `${baseUrl}?fromDate=${fromDate}&toDate=${toDate}&size=${size}&page=${page}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getAllAgentPassbookDetails({ fromDate, toDate, size = 100, page = 0,agentUnqId }) {
  const baseUrl = process.env.REACT_APP_GET_ALL_PASSBOOK_DETAILS;
  const url = `${baseUrl}?fromDate=${fromDate}&toDate=${toDate}&size=${size}&page=${page}&agentUnqId=${agentUnqId}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: error.body ? await error.json() : true
    };
    throw err;
  }
}



export const provisioningService = {
  getSiteByName,
  createOrganization,
  editOrganization,
  deleteOrgById,
  getOrgDetailsbyID,
  createSite,
  editSite,
  getLocaleList,
  getTimeZoneList,
  createUser,
  editUser,
  getOrganizations,
  getOrgByName,
  getSites,
  getSiteById,
  getSiteByUUID,
  getSitesByOrgName,
  getAllSitesByOrg,
  getUsers,
  getOrgHeirarchy,
  validateDevice,
  getPrivileges,
  updateSitesBookmarks,
  getRoles,
  createRole,
  getSubOrgHeirarchy,
  getSubOrganizationsByOrgName,
  getSubOrganizationsByOrgNameWithoutNestedObject,
  getSubOrganizationsMetaList,
  getSitesBySubOrgs,
  getPrivilegesByRoleName,
  getUserAssociatedSites,
  getOrgMetaList,
  getChillingUnitData,
  changePassword,
  resetUserPassword,
  getUserByUserName,
  setReportHeaders,
  getTicketIssues,
  postTicketIssues,
  getReportsData,
  getCheckListTemplates,
  generateOtp,
  verifyOtp,
  verifyOtpNew,
  getAllOrgList,
  getTypeAndCategoryInLoan,
  getLoanInsurance,
  createLoanInsurance,
  getLoanInsuranceByProductId,
  editLoanInsurance,
  fetchRetailerToken,
  getRetailerList,
  getRetailerById,
  editRetailer,
  getStatusValuesInRetailerEdit,
  getRetailerImportFailedRecords,
  fetchCODToken,
  fetchCODListBasedOnStatus,
  getAgentPaymentList,
  getAgentList,
  getOrgsAgent,
  getStatesAgent,
  getDistrictsAgent,
  getTaluksAgent,
  getVillagesAgent,
  validateAgent,
  createAgent,
  getAgentById,
  editAgent,
  getBmcRating,
  createChecklistTemplate,
  getChecklistName,
  editChecklistTemplate,
  getCheckListTemplateById,
  getOrgName,
  getOrgSiteStructure,
  getAllUsers,
  methodOfCooling,
  getOverAllBmcRating,
  getOrgRatingStructure,
  getRatingTreeDate,
  getGeographyByPincode,
  getAllStates,
  getDistricts,
  getTaluk,
  getVillages,
  getAllOrgs,
  getCCByOrg,
  getVlccByOrg,
  getRolesByOrgId,
  uploadPaymentTemplate,
  updatePaymentDetails,
  deletePaymentDetails,
  getAllPassbookDetails,
  getAllAgentPassbookDetails
};

export async function provisioningServices(type, name, params, options) {
  let method = type.toUpperCase();
  let url = getUrl(type, name, params);
  let requestOptions = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken: localStorage.getItem("idToken")
    }
  };

  if (!!options) {
    requestOptions = { ...requestOptions, options };
  }

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    // console.log(error);
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

function getUrl(type, name, params) {
  if (type === "get") {
    switch (name) {
      case "siteByName":
        return `${process.env.REACT_APP_PROVISIONING_GET_SITE}/siteName?name=${params.siteName}`;
      case "orgDetailsbyID":
        return `${process.env.REACT_APP_PROVISIONING_GET_ORGANIZATION_BY_NAME}/${params.id}`;
      case "organizations":
        return `${process.env.REACT_APP_PROVISIONING_GET_ALL_ORGANIZATIONS}?page=${params.pageIndex}&size=${params.pageSize}`;
      case "orgByName":
        return `${process.env.REACT_APP_PROVISIONING_GET_ORGANIZATION_BY_NAME}?orgName=${params.name}`;
      case "sites":
        return `${process.env.REACT_APP_PROVISIONING_GET_SITES}?page=${params.pageIndex}&size=${params.pageSize}`;
      case "siteById":
        return `/authgateway/provisioningService/sites/${params.siteId}`;
      case "siteByUUID":
        return `/authgateway/provisioningService/sites?uuid=${params.uuid}`;
      case "sitesByOrgName":
        return `${process.env.REACT_APP_PROVISIONING_GET_SITES_BY_ORG_NAME}?orgName=${params.orgName}`;
      case "users":
        return `${process.env.REACT_APP_PROVISIONING_GET_USERS}?page=${params.pageIndex}&size=${params.pageSize}`;
      case "orgHeirarchy":
        return `${process.env.REACT_APP_PROVISIONING_GET_ORGANIZATION_HEIRARCHY}/${params.orgId}`;
      case "validateDevice":
        return `${process.env.REACT_APP_PROVISIONING_VALIDATE_DEVICE}/${params.type}/${params.serialNumber}`;
      case "privileges":
        return process.env.REACT_APP_PROVISIONING_GET_PRIVILEGES;
      case "roles":
        return `/authgateway/provisioningService/roles?orgName=${params.organization}`;
      case "subOrgHeirarchy":
        return `${process.env.REACT_APP_PROVISIONING_GET_ORGANIZATION_SUBHEIRARCHY}?orgName=${params.suborg}`;
      case "subOrganizationsByOrgName":
        return `${process.env.REACT_APP_PROVISIONING_GET_SUB_ORGANIZATIONS_BY_ORG_NAME}?orgName=${params.orgName}&page=${params.pageIndex}&size=${params.pageSize}`;
      case "subOrganizationsMetaList":
        return `${process.env.REACT_APP_PROVISIONING_GET_SUB_ORGANIZATIONS_META_LIST}${params.orgName}`;
      case "sitesBySubOrgs":
        return `${process.env.REACT_APP_PROVISIONING_GET_SITES_BY_SUB_ORGS}id?subOrganizationName=${params.subOrgs}&page=${params.pageIndex}&size=${params.pageSize}`;
      case "privilegesByRoleName":
        return `/authgateway/provisioningService/roles/${params.role}`;
      case "userAssociatedSites":
        return `/authgateway/provisioningService/users/allSitesAssociation?userName=${params.userName}&page=${params.pageIndex}&size=${params.pageSize}`;
      case "orgMetaList":
        return `/authgateway/provisioningService/organizations/orgMetaList?orgName=${params.org}`;
      case "chillingUnitData":
        return `${process.env.REACT_APP_PROVISIONING_CHILLING_UNIT_DATA}${params.siteId}/chillingUnits/all`;
      case "userByUserName":
        return `/authgateway/provisioningService/users/userDetails?userName=${params.user}`;
      case "ticketIssues":
        return `/`;
      case "postTicketIssues":
        return `/`;
      case "reportsData":
        return `/`;
      case "checkListTemplates":
        return `/`;
      case "bmcRating":
        return `/authgateway/ratingService/rating/ratingDetails/${params.bmcId}`;
      case "rolesByOrgId":
        return `/authgateway/provisioningService/roles/orgId?orgId=${params.orgId}`
      default:
        return "/";
    }
  } else if (type === "post" || type === "put") {
    switch (name) {
      case "createOrganization":
        return process.env.REACT_APP_PROVISIONING_CREATE_ORGANIZATION;
      case "editOrganization":
        return process.env.REACT_APP_PROVISIONING_CREATE_ORGANIZATION;
      case "createSite":
        return process.env.REACT_APP_PROVISIONING_CREATE_SITE;
      case "editSite":
        return `${process.env.REACT_APP_PROVISIONING_CREATE_SITE}/${params.siteId}`;
      case "createUser":
        return process.env.REACT_APP_PROVISIONING_CREATE_USER;
      case "editUser":
        return `${process.env.REACT_APP_PROVISIONING_CREATE_USER}/${params.userId}`;
      case "createRole":
        return process.env.REACT_APP_PROVISIONING_CREATE_ROLE;
      case "changePassword":
        return "/authgateway/provisioningService/users/updatePassword";
      case "resetUserPassword":
        return `/authgateway/provisioningService/users/resetPassword?userName=${params.userName}`;
      default:
        return "/";
    }
  } else {
    return "/";
  }
}
