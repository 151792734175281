import React, { useEffect, useState, useContext, useMemo, useCallback, useRef ,useLayoutEffect} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { provisioningService } from "../../../services/provisioningServices";
import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { errorContext } from "context/errorContext";
import IconButton from "@material-ui/core/IconButton";
import Swal from "sweetalert2";

const formatDate = (date) => new Date(date).toISOString().split("T")[0];

const AgentPassbookDetails = () => {
  const inputRefs = useRef({});
  const { t } = useTranslation();
  const { dispatch: errorDispatch } = useContext(errorContext);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTableUI, setShowTableUI] = useState(false);
  const [passbookData, setPassbookData] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [editingRowId, setEditingRowId] = useState(null);
  const [agentUnqId, setAgentUnqId] = useState("");
  const [tableData, setTableData] = useState([]);


  const [focusedCell, setFocusedCell] = useState(null);
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const [startDate, setStartDate] = useState(formatDate(thirtyDaysAgo));
  const [endDate, setEndDate] = useState(formatDate(today));
  const handleStartDateChange = (event) => setStartDate(event.target.value);
  const handleEndDateChange = (event) => setEndDate(event.target.value);

  const fetchPassbookDetails = useCallback(async () => {
    setLoading(true);
    try {
      const requestObject = {
        fromDate: startDate,
        toDate: endDate,
        page: 0,
        size: 5000,
      };

      const data = await provisioningService.getAllPassbookDetails(requestObject);
      let tempData = data.content;
      tempData = tempData.map((item) =>{
        let objj = {
          "agentUnqId": item.agentUnqId,
          "id": item.id,
          "mepCode": item.mepCode,
          "mobileNumber": item.mobileNumber,
          "modeOfPayment": item.modeOfPayment,
          "netAmount": item.netAmount,
          "paymentDateTime": item.paymentDateTime,
          "reason": item.reason,
          "addition1": item.detailsBreakUp.addition1,
          "addition2": item.detailsBreakUp.addition2,
          "commission": item.detailsBreakUp.commission,
          "deduction": item.detailsBreakUp.deduction,
          "deduction2": item.detailsBreakUp.deduction2,
          "deduction3": item.detailsBreakUp.deduction3,
          "netPayableAmount": item.detailsBreakUp.netPayableAmount,
          "payableAmount": item.detailsBreakUp.payableAmount,
          "previousDue": item.detailsBreakUp.previousDue,
          "recovery": item.detailsBreakUp.recovery,
          "securityDeduction": item.detailsBreakUp.securityDeduction,
          "totalSecurity": item.detailsBreakUp.totalSecurity
        };
        return objj
      })
      setPassbookData(tempData);
      setShowTableUI(true);
    } catch (error) {
      console.error("Error fetching passbook details:", error);
      setPassbookData([]);
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    fetchPassbookDetails();
  }, [fetchPassbookDetails]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setShowTableUI(false);

    if (!startDate || !endDate || !agentUnqId) {
      alert("All fields are required!");
      setLoading(false);
      return;
    }
   
    const requestObject = {
      fromDate: formatDate(startDate),
      toDate: formatDate(endDate),
      page: 0,
      size: 5000,
      ...(agentUnqId && { agentUnqId })

    };

    try {
      console.log("Request Object:", requestObject);

      const data = await provisioningService.getAllAgentPassbookDetails(requestObject);
      console.log("data:", data);

     
      if (data && data.content) {
        let tempData = data.content;
      tempData = tempData.map((item) =>{
        let objj = {
          "agentUnqId": item.agentUnqId,
          "id": item.id,
          "mepCode": item.mepCode,
          "mobileNumber": item.mobileNumber,
          "modeOfPayment": item.modeOfPayment,
          "netAmount": item.netAmount,
          "paymentDateTime": item.paymentDateTime,
          "reason": item.reason,
          "addition1": item.detailsBreakUp.addition1,
          "addition2": item.detailsBreakUp.addition2,
          "commission": item.detailsBreakUp.commission,
          "deduction": item.detailsBreakUp.deduction,
          "deduction2": item.detailsBreakUp.deduction2,
          "deduction3": item.detailsBreakUp.deduction3,
          "netPayableAmount": item.detailsBreakUp.netPayableAmount,
          "payableAmount": item.detailsBreakUp.payableAmount,
          "previousDue": item.detailsBreakUp.previousDue,
          "recovery": item.detailsBreakUp.recovery,
          "securityDeduction": item.detailsBreakUp.securityDeduction,
          "totalSecurity": item.detailsBreakUp.totalSecurity
        };
        return objj
      })
        setPassbookData(tempData);
        setShowTableUI(true);
      } else {
        setPassbookData([]); 
      Swal.fire({
        icon: "error",
        title: "No Data Found",
        text: "No passbook records found.",
      });
      }
    } catch (error) {
      console.error("Error fetching agent data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No data available for this Agent Unique ID"
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!agentUnqId) {
      fetchPassbookDetails(); 
      setShowTableUI(true); 
    }
  }, [agentUnqId]);
  
  const handleDeleteClick = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You wants to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await provisioningService.deletePaymentDetails(id);
          setPassbookData((prevData) => prevData.filter((item) => item.id !== id));
  
          Swal.fire({
            title: "Deleted!",
            text: "Data deleted successfully!",
            imageUrl: "https://cdn-icons-png.flaticon.com/512/845/845646.png", // Custom green success icon
            imageWidth: 80,
            imageHeight: 80,
            confirmButtonText: "OK",
          });
          
        } catch (error) {
          console.error("Failed to delete data:", error);
  
          Swal.fire({
            title: "Error!",
            text: "Failed to delete data. Please try again.",
            imageUrl: "https://cdn-icons-png.flaticon.com/512/753/753345.png", // Custom error icon
            imageWidth: 80,
            imageHeight: 80,
            confirmButtonText: "OK",
          });
          
        }
      }
    });
  };

  
  const handleEditClick = (row) => {
    setEditingRowId(row.original.id);
    setEditedData({ ...row.original });
  
    setFocusedCell(`${row.original.id}-commission`); 
  };
  
  const handleEditChange = (columnId, value) => {
    setEditedData((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  };
  
  const handleSaveEdit = async () => {
    try {
      await provisioningService.updatePaymentDetails(editedData);
  
      setPassbookData((prevData) =>
        prevData.map((item) =>
          item.id === editedData.id ? { ...item, ...editedData } : item
        )
      );
  
      setEditingRowId(null);
      setEditedData({});
      setFocusedCell(null); 
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  
  const handleCellClick = (rowId, columnId) => {
    setFocusedCell(`${rowId}-${columnId}`);
  };
  const EditableCell = ({ cell }) => {
    const { row, column } = cell; 
    const isEditing = editingRowId === row.original.id && focusedCell === `${row.original.id}-${column.id}`;
    const cellKey = `${row.original.id}-${column.id}`;
  
    return (
      <span 
      onClick={() => handleCellClick(row.original.id, column.id)} 
      style={{ display: "inline-block", minWidth: "100px" }}
    >
      {isEditing ? (
        <TextField
          value={editedData[column.id] || ""}
          onChange={(e) => handleEditChange(column.id, e.target.value)}
          variant="outlined" 
          size="small"
          autoFocus 
        />
      ) : (
        row.original[column.id]
      )}
    </span>
    
    );
  };
  
  const columns = useMemo(
    () => [
      { Header: "Sl.No", accessor: "serial", Cell: ({ row }) => row.index + 1 },
      { Header: t("Payment Date Time"), accessor: "paymentDateTime" },
      { Header: t("Agent UnqId"), accessor: "agentUnqId" },
      { Header: t("MEP Code"), accessor: "mepCode" },
      { Header: t("Mobile Number"), accessor: "mobileNumber" },

      { Header: t("Commission"), accessor: "commission", Cell: (cell) => <EditableCell cell={cell} /> },
      { Header: t("Security Deduction"), accessor: "securityDeduction", Cell: (cell) => <EditableCell cell={cell} /> },
      { Header: t("Deduction"), accessor: "deduction", Cell: (cell) => <EditableCell cell={cell} /> },
      { Header: t("Previous Due"), accessor: "previousDue", Cell: (cell) => <EditableCell cell={cell} /> },
      { Header: t("Total Security"), accessor: "totalSecurity", Cell: (cell) => <EditableCell cell={cell} /> },
      { Header: t("Payable Amount"), accessor: "payableAmount", Cell: (cell) => <EditableCell cell={cell} /> },
      
      {
        Header: "Edit",
        Cell: ({ row }) =>
          editingRowId === row.original.id ? (
            <>
              <IconButton onClick={handleSaveEdit}>
                <i className="fa fa-save"></i>
              </IconButton>
              <IconButton onClick={() => setEditingRowId(null)}>
                <i className="fa fa-times"></i>
              </IconButton>
            </>
          ) : (
            <IconButton onClick={() => handleEditClick(row)}>
              <i className="fa fa-edit"></i>
            </IconButton>
          ),
      },
      {
        Header: "Delete",
        Cell: ({ row }) => (
          <IconButton onClick={() => handleDeleteClick(row.original.id)}>
            <i className="fa fa-trash"></i>
          </IconButton>
        ),
      },
    ],
    [t, editingRowId, editedData, focusedCell]
  );


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PageHeader 
          title="Agent Passbook Details" 
          breadcrumbs={[{ label: "Agent Payment" }, { label: "Agent Passbook Details" }]} 
        />
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={2} style={{ padding: "1.5em" }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="startDate"
                  value={startDate}
                  onChange={handleStartDateChange}
                  type="date"
                  label={t("From Date")}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="endDate"
                  value={endDate}
                  onChange={handleEndDateChange}
                  type="date"
                  label={t("To Date")}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="agentUnqId"
                  value={agentUnqId}
                  onChange={(e) => setAgentUnqId(e.target.value)}
                  label={t("Agent Unique ID")}
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    marginRight: "6px",
                    background: "linear-gradient(to bottom, #003366, #66ccff)", 
                    color: "white", 
                    width: "170px", 
                    height: "35px", 
                    fontSize: "12px" 
                  }}
                >
                  {t("Device_Data_Submit_Label")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          showTableUI && (
            <Table
              columns={columns}
              data={passbookData || []}
              noDataMessage={t("No Data Found")}
              fileName={"Agent Passbook Details.xls"}
            />
          )
        )}
      </Grid>
    </Grid>
  );
};

export default AgentPassbookDetails;
