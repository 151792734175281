import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Paper, Grid, CircularProgress } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import PageHeader from "components/layout/PageHeader";
import { AgentPaymentImportService } from "../../../services/agentpayment-import"; 
import { errorContext } from "context/errorContext";
import { provisioningService } from "services/provisioningServices";
import Swal from "sweetalert2";

const ImportAgentPassbookDetails = () => {

  const agentPaymentImportService = new AgentPaymentImportService();
  const fileInputRef = useRef(null);  

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [uploading, setUploading] = useState(false);

  const { dispatch: errorDispatch } = useContext(errorContext);

   useEffect(() => {
          provisioningService
          .verifyOtpNew('1313131313')
          .then((res) => {
            const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
          })
          .catch(error => {
            errorDispatch({
              type: "ERROR",
              error
            });
          });
      }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  const uploadAgentPayment = async () => {
    if (!selectedFile) {
      Swal.fire({
        icon: "warning",
        title: "No File Selected",
        text: "Please select a file before uploading.",
        confirmButtonText: "OK",
      });
      return;
    }
  
    const data = new FormData();
    data.append("file", selectedFile);
    setUploading(true);
  
    try {
      const response = await provisioningService.uploadPaymentTemplate(data);
  
      console.log("Upload Response:", response);
  
      // if (!response || !response.status) {
      //   throw new Error("Invalid API response format");
      // }
  
      if (response.status === 200) {
        setSelectedFile(null);
        setFileName("");
        if (fileInputRef.current) fileInputRef.current.value = "";
  
        Swal.fire({
          title: "Upload Successful",
          text: "File uploaded successfully!",
          imageUrl: "https://cdn-icons-png.flaticon.com/512/845/845646.png", // Custom success icon
          imageWidth: 80,
          imageHeight: 80,
          confirmButtonText: "OK",
        });
        
      } else {
        throw new Error(`Unexpected response: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error("Upload Error:", error);
  
      Swal.fire({
        title: "Upload Failed",
        text: "Something went wrong. Please try again.",
        imageUrl: "https://cdn-icons-png.flaticon.com/512/463/463612.png", // Custom error icon
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK",
      });
      
    } finally {
      setUploading(false);
    }
  };
  

  const handleReset = () => {
    setSelectedFile(null);
    setFileName("");
    if (fileInputRef.current) fileInputRef.current.value = ""; 
  };

 

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: "15px" }}>
        <PageHeader
          title="Import Agent Passbook Details"
          breadcrumbs={[
            { label: "Agent Payment" },
            { label: "Import Agent Passbook Details" },
          ]}
        />
      </Grid>

      <Grid item xs={8}>
        <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
          <h3>Upload File</h3>

          <input
            type="file"
            onChange={handleFileChange}
            accept=".xlsx, .xls, .csv"
            style={{ display: "none" }}
            id="file-input"
            ref={fileInputRef} 
          />

          <label htmlFor="file-input">
            <Button
              variant="contained"
              component="span"
              color="primary"
              startIcon={<CloudUploadIcon />}
            >
              Browse
            </Button>
          </label>

          {fileName && (
            <div style={{ marginTop: "10px", color: "#166aab" }}>
              <strong>Selected File:</strong> {fileName}
            </div>
          )}

          {selectedFile && (
            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleReset}
                style={{ marginRight: "10px" }}
                disabled={uploading}
              >
                Clear
              </Button>

              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                onClick={uploadAgentPayment}
                disabled={uploading}
              >
                {uploading ? <CircularProgress size={24} /> : "Upload"}
              </Button>
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ImportAgentPassbookDetails;
