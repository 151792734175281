import React, { useState, useEffect, useContext } from "react";

import home from "../../assets/sidebar-icons/home.png";
import homeWhite from "../../assets/sidebar-icons/home-white.png";
import config from "../../assets/sidebar-icons/configurationB.png";
import configWhite from "../../assets/sidebar-icons/configurationW.png";
import createUserWhite from "../../assets/sidebar-icons/create-user-white.png";
import sitesWhite from "../../assets/sidebar-icons/sites-white.png";
import roleWhite from "../../assets/sidebar-icons/role-white.png";
import organizationWhite from "../../assets/sidebar-icons/org-white.png";
import alertsBlue from "../../assets/sidebar-icons/alertsBlue.png";
import alertsWhite from "../../assets/sidebar-icons/alertsWhite.png";
// import monitoring from "../../assets/sidebar-icons/monitoring.png";
import devicelink from "../../assets/sidebar-icons/devicelink.png";
import hovering from "../../assets/sidebar-icons/hovering.png";
import graph from "../../assets/sidebar-icons/graph.png";
import graphwhite from "../../assets/sidebar-icons/graphwhite.png";
import importWhite from "../../assets/sidebar-icons/import-white.png";
import reportCategory from "../../assets/sidebar-icons/reportsBlue.png";
import ReportsWhite from "../../assets/sidebar-icons/reportsWhite.png";
import nestedReports from "../../assets/sidebar-icons/insideReportIcon.png";
import moreIcon from "../../assets/sidebar-icons/14.png";
import faq from "../../assets/sidebar-icons/faq.png";
import faqWhtie from "../../assets/sidebar-icons/faq-white.png";
import PrevM from "../../assets/sidebar-icons/PrevM.png";
import PrevMWhite from "../../assets/sidebar-icons/PrevM-white.png";
import enterrecWhite from "../../assets/sidebar-icons/enterrecord-white.png";
import viewrecWhite from "../../assets/sidebar-icons/viewrecord-white.png";
import checklistWhite from "../../assets/sidebar-icons/checklist-white.png";
import bmcratingWhite from "../../assets/sidebar-icons/bmcrating-white.png";
import analytics from "../../assets/sidebar-icons/analytics.png";
import analyticsWhite from "../../assets/sidebar-icons/analytics-white.png";
import ratingTemplate from "../../assets/sidebar-icons/ratingTemplate.png";
import ratingTemplateWhite from "../../assets/sidebar-icons/ratingTemplate-white.png";
import orgRatingWhite from "../../assets/sidebar-icons/org-rating.png"
import orgTree from "../../assets/org-tree.png";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MenuWithRouter from "./Menu";
import { provisioningService } from "../../services/provisioningServices";
import { errorContext } from "context/errorContext";
import { authContext } from "context/authContext";

const Menuitems = ({ handleClose, open }) => {
  const { dispatch: errorDispatch } = useContext(errorContext);
  const { t } = useTranslation();
  const numberOfReportsToBeDisplayed = 4;
  const [items, setItem] = useState([]);
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const { authState, authDispatch } = useContext(authContext);
  const { language } = authState;
  
  useEffect(() => {
    //console.log("user loading",user)    
    const orgName = provisioningService.getOrgName();

    let menuItems = [
      {
        name: t("menu_Home_Label"),
        icon: home,
        iconWhite: homeWhite,
        url: "/dashboard"
      },

      (!!user.uiPrivileges && user.uiPrivileges.includes("CONFIGURATION")) ||
      user.uiPrivileges.includes("ALL_PRIVILEGE")
        ? {
            name: t("menu_Configuration_Label"),
            icon: config,
            iconWhite: configWhite,
            children: [

              (!!user.uiPrivileges &&
                user.uiPrivileges.includes("LIST_NOTIFICATION")) ||
              user.uiPrivileges.includes("ALL_PRIVILEGE")
                ? {
                    name: t("menu_Site_Label"),
                    icon: sitesWhite,
                    url: "/configuration/notification",
                    iconWhite: sitesWhite,
                    children: [
                      (!!user.uiPrivileges &&
                        user.uiPrivileges.includes("CREATE_NOTIFICATION")) ||
                      user.uiPrivileges.includes("ALL_PRIVILEGE")?
                      {
                        name: t("menu_Create_Site_Label"),
                        iconWhite: sitesWhite,
                        url: "/configuration/createnotification"
                      }:null
                    ]
                  }
                : null,

                (!!user.uiPrivileges &&
                  user.uiPrivileges.includes("LIST_LOAN/INSURANCE")) ||
                user.uiPrivileges.includes("ALL_PRIVILEGE")
                  ? {
                      name: t("menu_Loan_Insurance_Label"),
                      url: "/configuration/loan-insurance-list",
                      iconWhite: checklistWhite
                    }
                  : null,

                (!!user.uiPrivileges && 
                  user.uiPrivileges.includes("VIEW_PRODUCT_TYPE")) ||
                  user.uiPrivileges.includes("ALL_PRIVILEGE")
                ? {
                    name: t("menu_Product_Label"),
                    icon: PrevMWhite,
                    url: "/configuration/productlist",
                    iconWhite: PrevMWhite,
                    children: [
                      {
                        name: t("menu_Create_Product_Label"),
                        iconWhite: PrevMWhite,
                        url: "/configuration/createproduct"
                      }
                    ]
                  }
                : null,

                (!!user.uiPrivileges && 
                  user.uiPrivileges.includes("VIEW_PRODUCT_TYPE")) ||
                  user.uiPrivileges.includes("ALL_PRIVILEGE")
                ? {
                    name: t("menu_Category_Label"),
                    icon: orgTree,
                    url: "/configuration/categorytree-display",
                    iconWhite: orgTree,
                    children: [
                      // {
                      //   name: t("menu_Create_Product_Label"),
                      //   iconWhite: PrevMWhite,
                      //   url: "/configuration/createproduct"
                      // }
                    ]
                  }
                : null,

                (!!user.uiPrivileges && 
                  user.uiPrivileges.includes("APPROVE")) ||
                  user.uiPrivileges.includes("ALL_PRIVILEGE")
                ? {
                    name: t("approve_product_label"),
                    icon: viewrecWhite,
                    url: "/configuration/approve",
                    iconWhite: viewrecWhite,
                    // children: [
                    //   {
                    //     name: t("menu_Create_Product_Label"),
                    //     iconWhite: PrevMWhite,
                    //     url: "/configuration/createproduct"
                    //   }
                    // ]
                  }
                : null,

                (!!user.uiPrivileges &&
                  user.uiPrivileges.includes("LIST_RETAILER")) ||
                user.uiPrivileges.includes("ALL_PRIVILEGE")
                  ? {
                      name: t("menu_Retailer_Label"),
                      icon: sitesWhite,
                      url: "/configuration/retailer-list",
                      iconWhite: sitesWhite,
                      children: [
                        {
                          name: t("menu_Import_Retailer_label"),
                          iconWhite: importWhite,
                          url: "/configuration/import-retailer"
                        },
                        {
                          name: t("menu_Retailer_Associate_label"),
                          iconWhite: sitesWhite,
                          url: "/configuration/retailer-associate-List"
                        },
                        {
                          name: t("menu_Retailer_Agent_Edit_Label"),
                          icon: sitesWhite,                         
                          url: "/configuration/retailer-associate-criteria",
                          iconWhite: sitesWhite,
                        }
                      ]
                    }
                  : null,

                  (!!user.uiPrivileges &&
                    user.uiPrivileges.includes("IMPORT_COD")) ||
                  user.uiPrivileges.includes("ALL_PRIVILEGE")
                    ? {
                            name: t("menu_Import_COD_label"),
                            iconWhite: importWhite,
                            url: "/configuration/import-cod",
                            children: [
                          {
                            name: t("menu_COD_label"),
                            iconWhite: importWhite,
                            url: "/configuration/cod-list"
                          }
                        ]
                        // name: t("menu_COD_label"),
                        // //icon: roleWhite,
                        // url: "/configuration/cod-list",
                        // iconWhite: checklistWhite,
                        // children: [
                        //   {
                        //     name: t("menu_Import_COD_label"),
                        //     iconWhite: importWhite,
                        //     url: "/configuration/import-cod"
                        //   }
                        // ]
                      }
                    : null,

                    // (!!user.uiPrivileges &&
                    //   user.uiPrivileges.includes("IMPORT_AGENT_PAYMENT")) ||
                    // user.uiPrivileges.includes("ALL_PRIVILEGE")
                    //   ? {
                    //       name: t("menu_Agent_Payment_Label"),
                    //       url: "/configuration/agentPaymentBlank-page",
                    //       icon: sitesWhite,                       
                    //       iconWhite: createUserWhite,
                    //       children: [
                    //         // {
                    //         //   name: t("menu_Agent_Payment_Label"),
                    //         //   icon: sitesWhite,
                    //         //   url: "/configuration/agent-payment-list",
                    //         //   iconWhite: sitesWhite
                    //         //   // name: t("menu_Import_Agent_Payment_label"),
                    //         //   // iconWhite: importWhite,
                    //         //   // url: "/configuration/import-agent-payment"
                    //         // },
                    //         {
                    //           name: t("menu_Agent_Passbook_Details_Label"),
                    //           iconWhite: sitesWhite,
                    //           url: "/configuration/agent-passbook-details"
                              
                        
                    //         },
                    //         {
                    //           name: t("menu_Import_Agent_Passbook_Details_Label"),
                    //           iconWhite: sitesWhite,
                    //           url: "/configuration/import-agent-passbook-details"
                        
                    //         }

                    //       ]
                    //     }
                    //   : null,

                    (!!user.uiPrivileges &&
                      user.uiPrivileges.includes("IMPORT_AGENT_PAYMENT")) ||
                    user.uiPrivileges.includes("ALL_PRIVILEGE")
                      ? {
                          name: t("menu_Agent_Payment_Label"),
                          icon: sitesWhite,
                          url: "/configuration/agentPaymentBlank-page",
                          iconWhite: sitesWhite,
                          children: [
                            
                            {
                              name: t("menu_Import_Agent_Passbook_Details_Label"),
                              iconWhite: importWhite,
                              url: "/configuration/import-agent-passbook-details"
                            },
                            {
                              name: t("menu_Agent_Passbook_Details_Label"),
                              iconWhite: sitesWhite,
                              url: "/configuration/agent-passbook-details"
                            }

                          ]
                        }
                      : null,
                  
                       (!!user.uiPrivileges &&
                user.uiPrivileges.includes("AGENT_LIST")) ||
              user.uiPrivileges.includes("ALL_PRIVILEGE")
                ? {
                    name: t("menu_Agent_Label"),
                    icon: createUserWhite,
                    url: "/configuration/agent-list",
                    iconWhite: createUserWhite,
                    children: [
                      {
                        name: t("menu_Add_Agent_label"),
                        iconWhite: createUserWhite,
                        url: "/configuration/agent-create"
                      },
                      {
                        name: t("menu_AgentCriteria_Label"),
                        icon: createUserWhite,
                        url: "/configuration/agent-criteria",
                        iconWhite: createUserWhite,
                      }
                    ]
                  }
                : null,


                // (!!user.uiPrivileges &&
                //   user.uiPrivileges.includes("AGENT_LIST")) ||
                // user.uiPrivileges.includes("ALL_PRIVILEGE")
                //   ? {
                //       name: t("menu_Retailer_Agent_Label"),
                //       icon: alertsBlue,
                //       url: "/configuration/associate-List",
                //       iconWhite: alertsBlue,
                //       children: [
                //         {
                //           name: t("menu_Retailer_Agent_Edit_Label"),
                //           icon: alertsBlue,                         
                //           url: "/configuration/associate-criteria",
                //           iconWhite: alertsBlue,
                //         }
                //       ]
                //     }
                //   : null,


                // (!!user.uiPrivileges &&
                //   user.uiPrivileges.includes("AGENT_LIST")) ||
                // user.uiPrivileges.includes("ALL_PRIVILEGE")
                //   ? {
                //       name: t("menu_Retailer_Agent_Label"),
                //       icon: organizationWhite,
                //       url: "/configuration/retailer-agent-list",
                //       iconWhite: organizationWhite,
                //       children: [                        
                //         {
                //           name: t("menu_Retailer_Agent_Edit_Label"),
                //           icon: organizationWhite,
                //           url: "/configuration/retailer-agent-criteria",
                //           iconWhite: organizationWhite,
                //         }
                //       ]
                //     }
                //   : null,
              
                  // (!!user.uiPrivileges &&
                  //   user.uiPrivileges.includes("LIST_RETAILER")) ||
                  // user.uiPrivileges.includes("ALL_PRIVILEGE")
                  //   ? {
                  //       name: t("menu_Farmer_Associate_Label"),
                  //       icon: ratingTemplate,
                  //       url: "/configuration/farmerassociation",
                  //       iconWhite: ratingTemplate,
                  //       children: [
                  //         // {
                  //         //   name: t("menu_Associate_Label"),
                  //         //   iconWhite: importWhite,
                  //         //   url: "/configuration/import-retailer"
                  //         // }
                  //       ]
                  //     }
                  //   : null,

                    (!!user.uiPrivileges &&
                      user.uiPrivileges.includes("FARMER_ASSOCIATION")) ||
                    user.uiPrivileges.includes("ALL_PRIVILEGE")
                      ? {
                          name: t("menu_Farmer_Associate_Label"),
                          icon: ratingTemplate,
                          url: "/configuration/criteria",
                          iconWhite: ratingTemplate,
                          children: [
                            {
                              name: t("Create_Farmer_Association"),
                              iconWhite: ratingTemplate,
                              url: "/configuration/farmerassociation"
                            }
                          ]
                        }
                      : null,                      

                      (!!user.uiPrivileges &&
                        user.uiPrivileges.includes("CUSTOMER_GROUP")) ||
                      user.uiPrivileges.includes("ALL_PRIVILEGE")
                        ? {
                            name: t("CustomerGroup_Form_CustomerGroup_Label"),
                            icon: ratingTemplate,
                            url: "/configuration/customergroup",
                            iconWhite: ratingTemplate,
                            children: [
                              (!!user.uiPrivileges &&
                                user.uiPrivileges.includes("CREATE_CUSTOMER_GROUP")) ||
                              user.uiPrivileges.includes("ALL_PRIVILEGE")?
                              {
                                name: t("CustomerGroup_Form_Create_CustomerGroup_Label"),
                                iconWhite: ratingTemplate,
                                url: "/configuration/create-customergroup"
                              }:null
                            ]
                          }
                        : null,
		                  

                    (!!user.uiPrivileges &&
                          user.uiPrivileges.includes("ITEM_LEVEL_CONFIGURATION")) ||
                        user.uiPrivileges.includes("ALL_PRIVILEGE")
                          ? {
                              name: t("menu_ItemLevelConfiguration_Label"),
                              icon: createUserWhite,
                              url: "/configuration/item-level-configuration",
                              iconWhite: createUserWhite,
                              
                            }
                          : null,
                          

                            
                    (!!user.uiPrivileges &&
                      user.uiPrivileges.includes("WAREHOUSE_ASSOCIATION")) ||
                      user.uiPrivileges.includes("ALL_PRIVILEGE")
                        ? {
                            name: t("menu_Warehouse_Label"),
                            url: "/configuration/warehouse-list",
                            iconWhite: roleWhite,
                            children: [
                              {
                                name: t("menu_Import_Warehouse_label"),
                                iconWhite: importWhite,
                                url: "/configuration/import-warehouse"
                               }
                             ]
                          }
                      : null,

		   (!!user.uiPrivileges &&
                        user.uiPrivileges.includes("CREATE_PBP")) ||
                      user.uiPrivileges.includes("ALL_PRIVILEGE")
                        ? {
                            name: t("menu_pbp_Label"),
                            icon: createUserWhite,
                            url: "/configuration/pbp_list",
                            iconWhite: createUserWhite,
                            children: [                       
                              
                              {
                                name: t("menu_Add_pbp_label"),
                                iconWhite: createUserWhite,
                                url: "/configuration/pbp_create",
                                icon: createUserWhite
                              },
                              
                            ]
                          }
                        : null,

                        (!!user.uiPrivileges &&
                           user.uiPrivileges.includes("BANNER_CONFIGURATION")) ||
                           user.uiPrivileges.includes("ALL_PRIVILEGE")
                          ? {
                              name: t("menu_banner_Label"),
                              icon: createUserWhite,
                              url: "/configuration/banner_list",
                              iconWhite: createUserWhite,
                              children: [
                                {
                                  name: t("menu_Add_banner_label"),
                                  iconWhite: createUserWhite,
                                  url: "/configuration/banner_create",
                                  icon: createUserWhite
                                },                                
                              ]
                            }
                          : null,

                          (!!user.uiPrivileges &&
                          user.uiPrivileges.includes("BANNER_CONFIGURATION")) ||   //privilege_name
                          //user.uiPrivileges.includes("ARTICLE_CONFIGURATION")) ||   //privilege_name
                          user.uiPrivileges.includes("ALL_PRIVILEGE")
                            ? {
                                name: t("menu_article_Label"),
                                icon: createUserWhite,
                                //url: "/configuration/article_list",
                                url: "/configuration/article_listHierarchy",
                                iconWhite: createUserWhite,
                                children: [
                                  {
                                    name: t("menu_Add_article_label"),
                                    iconWhite: createUserWhite,
                                    url: "/configuration/article_create",
                                    icon: createUserWhite
                                  },                                  
                                ]
                              }
                            : null,

                            (!!user.uiPrivileges &&
                              user.uiPrivileges.includes("BANNER_CONFIGURATION")) ||
                              user.uiPrivileges.includes("ALL_PRIVILEGE")
                             ? {
                                   name: t("menu_appConfig_Label"),
                                   url: "/configuration/appConfigBlank_Form",                       
                                   iconWhite: createUserWhite,
                                   children: [
                                     {
                                       name: t("menu_Add_template_label"),
                                       iconWhite: createUserWhite,                                        
                                       url: "/configuration/appConfig_list",
                                       //icon: createUserWhite
                                     },  
                                     {
                                       name: t("menu_Add_appConfig_label"),
                                       iconWhite: createUserWhite,                                        
                                       url: "/configuration/appConfigNew_list",
                                       //icon: createUserWhite
                                     },  
                                     {
                                       name: t("menu_Add_appConfigMapping_label"),
                                       iconWhite: createUserWhite,
                                       url: "/configuration/appConfigMapping_list",
                                       //icon: createUserWhite
                                     }                                   
                                   ]
                                 }
                               : null,   

                        (!!user.uiPrivileges &&
                          user.uiPrivileges.includes("ROLE_CONFIGURATION")) ||
                        user.uiPrivileges.includes("ALL_PRIVILEGE")
                          ? {
                              name: t("menu_Role_Label"),
                              url: "/configuration/rolelist?page=0&size=1000",
                              iconWhite: roleWhite,
                              children: [
                                {
                                  name: t("menu_Create_Role_Label"),
                                  iconWhite: roleWhite,
                                  url: "/configuration/role"
                                }
                              ]
                            }
                          : null,

                        (!!user.uiPrivileges &&
                          user.uiPrivileges.includes("USER_CONFIGURATION")) ||
                        user.uiPrivileges.includes("ALL_PRIVILEGE")
                          ? {
                              name: t("menu_User_Label"),
                              iconWhite: createUserWhite,
                              url: "/configuration/userlist?page=0&paging=&size=1000",
                              children: [
                                {
                                  name: t("menu_Create_User_Label"),
                                  iconWhite: createUserWhite,
                                  url: "/configuration/user"
                                }
                              ]
                            }
                          : null,
              
            ]
          }
        : null

    ];

    const getData = async () => {
      if (
        (!!user.uiPrivileges && user.uiPrivileges.includes("REPORTS")) ||
        user.uiPrivileges.includes("ALL_PRIVILEGES")
      ) {
        let reports = {
          name: t("menu_Reports_Label"),
          icon: reportCategory,
          iconWhite: ReportsWhite
        };
        provisioningService
          .getReportsData(language)
          .then(data => {
            console.log("From Sidebar Comp :- ", data);
            var children = [];
            for (var i = 0; i < data.REPORTS.length; i++) {
              var more = false;
              // console.log(data.REPORTS[0]);
              if (
                data.REPORTS[i].values.length > numberOfReportsToBeDisplayed
              ) {
                more = true;
              }
              children.push({
                name: data.REPORTS[i].key,
                icon: nestedReports,
                iconWhite: nestedReports,
                children: createChild(data.REPORTS[i].values, more)
              }); //added +1 to incorporate 1 extra place in map to incorporate more feature
            }
            reports.url = "/reports";
            if (data.REPORTS.length > numberOfReportsToBeDisplayed)
              children.push({
                name: "more",
                url: "/reports",
                icon: moreIcon
              });
            if (
              (!!user.uiPrivileges &&
                user.uiPrivileges.includes("VIEW_REPORTS")) ||
              user.uiPrivileges.includes("ALL_PRIVILEGES")
            ) {
              reports.children = children;
            }
            menuItems.splice(menuItems.length - 1, 0, reports);
            setItem([...menuItems]);
          })
          .catch(error => {
            console.error("error ", error);
            if (
              (!!user.uiPrivileges && user.uiPrivileges.includes("REPORTS")) ||
              user.uiPrivileges.includes("ALL_PRIVILEGES")
            ) {
              reports.url = "/500";
              menuItems.splice(menuItems.length - 1, 0, reports);
            }
            setItem([...menuItems]);
          });
      } else {
        setItem([...menuItems]);
      }
    };
    getData();
  }, [language]);

  const createChild = (data, more) => {
    var arr = [];
    //added +1 to incorporate 1 extra place in map to incorporate more feature
    data.slice(0, numberOfReportsToBeDisplayed + 1).map((pv, index) => {
      if (more && index === numberOfReportsToBeDisplayed)
        arr.push({
          url: "/reports",
          name: "more",
          icon: moreIcon,
          iconWhite: moreIcon
        });
      else
        arr.push({
          id: pv.id,
          name: pv.displayName,
          icon: nestedReports,
          iconWhite: nestedReports
        });
    });
    return arr;
  };

  return items.map((item, index) =>
    !!item ? (
      <MenuWithRouter
        handleClose={handleClose}
        isM={false}
        items={item}
        key={index}
        link={item.url}
        level={0}
        draweropen={open}
      />
    ) : null
  );
};

export default Menuitems;
